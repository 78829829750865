<template>
  <div class="field">
    <div class="field">
      <label class="label">Название</label>
      <div class="control ">
        <input v-model="site.name" class="input" type="text" placeholder="Название">

      </div>
    </div>
    <label class="label">Адресс</label>
    <div class="control ">
      <input v-model="site.url" class="input" type="text" placeholder="Адресс">
    </div>
  </div>
  <div class="field">
    <label class="label">Админка</label>
    <div class="control ">
      <input v-model="site.admin_url" class="input" type="text" placeholder="Админка">
    </div>
  </div>
  <div class="field is-grouped">
    <div class="control">
      <button @click="editSite(site)" class="button is-link">Сохранить</button>
    </div>
  </div>
</template>
<script>
import {storage} from '@/storage/storageBoardData';
import {editSite} from "@/modules/actions";

export default {
  name: 'board-site-create',
  setup() {
   const site = {...storage.value};

    return {
      editSite,
      site
    }
  }
}
</script>
