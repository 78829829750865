import axios from './axios';
import router from "@/router";

const setToken = (token) => {
    axios.defaults.headers.common['Authorization'] = token;
    localStorage.setItem('access_token', token);
}

const getUserData = async () => {
    return await axios({
        method: 'get',
        url: 'users/me',
    }).then(response => response.data).catch(error => {
        console.log(error);
    })
}

const setUserData = async () => {
    const data = await getUserData();
    localStorage.setItem('user', JSON.stringify(data));
}

const login = (data) => {
    return axios({
        method: 'post',
        url: 'auth/jwt/login',
        data: data,
    }).then(async response => {
        const token = `Bearer ${response.data.access_token}`;

        if (token) {
            setToken(token);
            await setUserData()
            router.push('/');
        }

        return response
    }).catch(error => {
        console.log(error);
    })
}

export const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');
}

export default async function auth(username, pass) {
    const data = new FormData();
    data.append('username', username)
    data.append('password', pass)

    const response = await login(data);

    return response
}

