<template>
  <div class="sidebar-list">
    <div class="sidebar-list__action">
      <button @click="openSiteCreate()"
              class="button is-primary is-fullwidth" v-if="isSuperuser()">
        Добавить
      </button>
    </div>

    <ul class="sidebar-list__body" ref="sitesWrapper">
      <li v-for="site in sites" :key="`${site.name}-${site.id}`"
          @click="setCurrentTab(`${site.name}-${site.id}`); openSite(site.id)">
        <div class="button-link" :class="{'is-active': currentTab === `${site.name}-${site.id}`}">
          <strong v-if="site.name">{{ site.name }}</strong>{{ site.url }}
        </div>
      </li>
    </ul>

  </div>
</template>
<script>
import {setCurrentTab, currentTab} from "@/storage/storageBoardCurrentTab";
import scrollbar from "@/modules/scrollbar";
import {loadSites, openSite, openSiteCreate, sites} from "@/modules/controls";
import {ref, onMounted} from 'vue';
import {isSuperuser} from "@/storage/storageUserData";

export default {
  name: 'board-site-list',

  setup() {
    const sitesWrapper = ref(null);

    onMounted(async () => {
      await loadSites();
      scrollbar(sitesWrapper.value);
    })


    return {
      currentTab,
      sites,
      sitesWrapper,
      setCurrentTab,
      openSite,
      openSiteCreate,
      isSuperuser
    }
  }
}
</script>

