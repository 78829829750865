<template>

  <div class="head-card board-card">
    <div class="head-card__icon">
      <img src="@/assets/user.svg" alt="icon">
    </div>
    <div class="head-card__name">
      {{ user['first_name'] }} {{ user['last_name'] }}
    </div>
    <div class="head-card__action">
      <a href="javascript:void(0)" class="head-card__button" @click="setBoardComponent(BoardUserEdit)">
        <img src="@/assets/edit.svg" alt="edit">
      </a>
      <a href="javascript:void(0)" class="head-card__button" @click="showAlertDelete(deleteUser, user.id)">
        <img src="@/assets/delete.svg" alt="delete">
      </a>
    </div>
  </div>
  <div class="board-card">
    <ul class="board-card__list">
      <li>
        <div class="info-line">
          <img src="@/assets/at.svg" alt="www">
          <span>{{ user.email }}</span>
        </div>
      </li>
      <li v-if="user['is_superuser']">
        <div class="info-line">
          <img src="@/assets/dot.svg" alt="panel">
          <span>superuser</span>
        </div>
      </li>
    </ul>
  </div>

</template>
<script>

import {storage as user} from '@/storage/storageBoardData';
import BoardUserEdit from "@/components/BoardUserEdit";
import {setStorage as setBoardComponent} from "@/storage/storageBoardComponent";
import {showAlertDelete} from "@/modules/swal";
import {deleteUser} from "@/modules/actions";

export default {
  name: 'board-user',

  setup() {

    return {
      user,
      setBoardComponent,
      showAlertDelete,
      deleteUser,
      BoardUserEdit,
    }
  },

}
</script>

