import {getAccess} from "@/modules/actions";
import {readonly, ref} from "vue";
import BoardAccessCreate from "@/components/BoardAccessCreate";
import {setStorage as setBoardComponent} from "@/storage/storageBoardComponent";


const storage = ref([]);

const access = readonly(storage);

const openAccessCreate = () => {
    setBoardComponent(BoardAccessCreate);
}

const loadAccess = async () => {
    const response = await getAccess();
    storage.value = response.sort((a, b) => a.id > b.id ? 1 : -1);
}

export {
    access,
    openAccessCreate,
    loadAccess
}
