import {getUsers} from "@/modules/actionsUsers";
import {setStorage as setBoardComponent} from "@/storage/storageBoardComponent";
import BoardUser from "@/components/BoardUser";
import BoardUserCreate from "@/components/BoardUserCreate";
import {setStorage as setBoardData} from "@/storage/storageBoardData";
import {setCurrentTab} from "@/storage/storageBoardCurrentTab";
import {clearBoard} from "@/modules/controlsBase";
import {ref, readonly} from "vue";

const storage = ref(null);

const users = readonly(storage);

const loadUsers = async () => {
    const response = await getUsers()
    storage.value = response.sort((a, b) => a.id > b.id ? 1 : -1)
}

const openUser = (data) => {
    setBoardData(data);
    setBoardComponent(BoardUser);
};

const openUserCreate = () => {
    setBoardComponent(BoardUserCreate);
}

const updateUserList = async (user) => {
    await loadUsers();
    if (user) {
        openUser(user);
        setCurrentTab(`user-${user.id}`);
    } else {
        clearBoard();
    }
}

const updateAfterDeleteUser = async () => {
    await loadUsers();
        clearBoard();
}

export {
    openUserCreate,
    updateUserList,
    openUser,
    loadUsers,
    updateAfterDeleteUser,
    users
}
