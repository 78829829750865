import {setStorage as setBoardData, storage as siteStorage} from "@/storage/storageBoardData";
import {setStorage as setBoardComponent} from "@/storage/storageBoardComponent";
import {setCurrentTab} from "@/storage/storageBoardCurrentTab";
import BoardSite from "@/components/BoardSite";
import BoardSiteCreate from "@/components/BoardSiteCreate";
import {getSites, retrieveSite} from "@/modules/actions";
import {clearBoard} from "@/modules/controlsBase";
import {ref, readonly} from "vue";

const storage = ref(null);

const sites = readonly(storage);

const loadSites = async () => {
    const response = await getSites();
    storage.value = response.sort((a, b) => a.id > b.id ? 1 : -1);
}

const loadSite = async (id) => {
    return await retrieveSite(id)
}

const openSite = async (id) => {
    const data = await loadSite(id);
    setBoardData(data);
    setBoardComponent(BoardSite);
}

const openSiteCreate = () => {
    setBoardComponent(BoardSiteCreate);
}

const updateSiteList = async (site) => {
    await loadSites();

    if (site) {
        await openSite(site.id);
        setCurrentTab(`${site.name}-${site.id}`);
    } else {
        clearBoard();
    }
}

const updateAfterDeleteSite = async (site) => {
    await loadSites();
    if (site) {
        clearBoard();
    }
}

const removeAccessFromSite = (id) => {
    const data = {...siteStorage.value}

    data.access_list = data.access_list.filter(item => {
        return item.id !== id
    })

    return setBoardData(data)
}

export {
    updateSiteList,
    openSite,
    loadSites,
    openSiteCreate,
    updateAfterDeleteSite,
    removeAccessFromSite,
    sites
}
