<template>
  <div class="head-card board-card">
    <div class="head-card__icon">
      <img src="@/assets/internet.svg" alt="icon">
    </div>
    <a :href="site.url" target="_blank" class="head-card__name">
      {{ site.name }}
    </a>
    <div class="head-card__action" v-if="isSuperuser()">
      <a href="javascript:void(0)" @click="openSiteEditor()" class="head-card__button">
        <img src="@/assets/edit.svg" alt="edit">
      </a>
      <a href="javascript:void(0)" class="head-card__button" @click="showAlertDelete(deleteSite, site.id)">
        <img src="@/assets/delete.svg" alt="delete">
      </a>
    </div>
  </div>
  <div class="board-card">
    <ul class="board-card__list">
      <li>
        <a class="info-line" :href="site.url" target="_blank">
          <img src="@/assets/www.svg" alt="www">
          <span>{{ site.url }}</span>
        </a>
      </li>
      <li>
        <ul class="inline-group">
          <li>
            <a class="info-line" :href="site.admin_url" target="_blank">
              <img src="@/assets/panel.svg" alt="panel">
              <span>{{ site.admin_url }}</span>
            </a>
          </li>
          <li>
            <button class="button is-link is-small is-outlined" @click="loginExternalSite(site.id)">
              Авторизоваться
            </button>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <board-access-list v-if="isSuperuser()"></board-access-list>
</template>
<script>
import BoardAccessList from "@/components/BoardAccessList";
import BoardSiteEdit from "@/components/BoardSiteEdit";
import {storage as site} from '@/storage/storageBoardData';
import {setStorage as setBoardComponent} from "@/storage/storageBoardComponent";
import {isSuperuser} from "@/storage/storageUserData";
import {deleteSite, loginExternalSite} from "@/modules/actions";
import {showAlertDelete} from "@/modules/swal";


export default {
  name: 'board-site',

  components: {
    BoardAccessList
  },

  setup() {

    const openSiteEditor = () => {
      setBoardComponent(BoardSiteEdit);
    }


    return {
      site,
      loginExternalSite,
      deleteSite,
      openSiteEditor,
      showAlertDelete,
      isSuperuser
    }
  },

}
</script>

