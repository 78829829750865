<template>
  <div class="board-card">
    <div class="field">
      <div class="field">
        <label class="label">Название</label>
        <div class="control ">
          <input v-model="site.name" class="input" type="text" placeholder="Название">
        </div>
      </div>
      <label class="label">Адресс</label>
      <div class="control ">
        <input v-model="site.url" class="input" type="text" placeholder="Адресс">
      </div>
    </div>
    <div class="field">
      <label class="label">Админка</label>
      <div class="control ">
        <input v-model="site.admin_url" class="input" type="text" placeholder="Админка">
      </div>
    </div>
    <div class="field is-grouped">
      <div class="control">
        <button @click="createSite(getSite)" class="button is-link">Сохранить</button>
      </div>
    </div>
  </div>
</template>
<script>
import {reactive, computed} from 'vue';
import {createSite} from "@/modules/actions";

export default {
  name: 'board-site-create',
  setup() {
    const site = reactive({
      url: '',
      admin_url: '',
      name: ''
    });

    const getSite = computed(() => {
      return {url: site.url, admin_url: site.admin_url, name: site.name}
    });

    return {
      getSite,
      createSite,
      site
    }
  }
}
</script>
