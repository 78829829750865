import axios from "@/modules/axios";
import {isSuperuser} from "@/storage/storageUserData"

export default {
    sites: {
        get: () => {
            return axios.get('sites').then(response => response)
        },

        retrieve: (id) => {
            const url = isSuperuser() ? `sites/${id}` : `staff-site/${id}`
            return axios.get(url).then(response => response)
        },

        post: (data) => {
            return axios({
                method: 'post',
                url: 'sites',
                data: data
            }).then(response => response)
        },

        put: (site) => {
            return axios({
                method: 'put', url: `sites/${site.id}`,
                data: {name: site.name, url: site.url, admin_url: site.admin_url}
            }).then(response => response)
        },

        delete: (id) => {
            return axios.delete(`sites/${id}`).then(response => response)
        }
    },

    users: {
        get: () => {
            return axios.get('users').then(response => response);
        },

        patch: (data) => {
            return axios({
                method: 'patch', url: `users/${data.id}`,
                data: {
                    first_name: data.first_name,
                    last_name: data.last_name,
                    email: data.email,
                    password: data.password,
                    is_superuser: data.is_superuser
                }
            }).then(response => {
                return response
            })
        },

        post: (data) => {
            return axios({
                method: 'post', url: `users`,
                data: data
            }).then(response => {
                return response
            })
        },

        delete: (id) => {
            return axios.delete(`users/${id}`).then(response => response)
        }
    },

    access: {
        get: () => {
            return axios.get('access').then(response => response)
        },

        post: (data) => {
            return axios({
                method: 'post',
                url: 'access',
                data: data
            }).then(response => response)
        },

        put: (data) => {
            const {id, access_level} = data;
            let access;

            if (access_level === 'staff') {
                access = 'superadmin'
            } else {
                access = 'staff'
            }

            return axios({
                method: 'put',
                url: `access/${id}`,
                data: {
                    access_level: access
                }
            }).then(response => response)
        },

        delete: (data) => {
            const {id} = data;
            return axios({
                method: 'delete',
                url: `access/${id}`,
            }).then(response => response)
        }
    },

    external: {
        get: (id) => {
            return axios.get(`external/auth?site_id=${id}`).then(r => {
                const data = r.data;
                window.location.href = data.url
                return data
            })
        }
    }
}
