<template>
  <div class="card">
    <h2 class="card__title">
      <span :class="['sidebar-tab', {active: currentSidebarTab === tab.sites}]"
            @click="setCurrentSidebarTab(tab.sites); openSiteList();">
        Сайты
      </span>
      <template v-if="isSuperuser()">
        <span class="separate">/</span>
        <span :class="['sidebar-tab', {active: currentSidebarTab === tab.users}]"
              @click="setCurrentSidebarTab(tab.users); OpenUserList();">
        Пользователи
      </span>
      </template>
    </h2>
    <component :is="currentSidebar"></component>
  </div>
</template>
<script>
import {ref, markRaw, reactive, onMounted} from 'vue';
import BoardSiteList from "@/components/BoardSiteList";
import BoardUserList from "@/components/BoardUserList";
import {isSuperuser} from "@/storage/storageUserData";

export default {
  name: 'board-sidebar',

  setup() {
    const tab = reactive({
      sites: 'sites',
      users: 'users'
    });
    const currentSidebarTab = ref('sites');
    const currentSidebar = ref(null);

    const setCurrentSidebarTab = (data) => {
      currentSidebarTab.value = data;
    }

    const setSidebar = (data) => {
      currentSidebar.value = markRaw(data);
    }

    const openSiteList = () => {
      setSidebar(BoardSiteList)
    };

    const OpenUserList = () => {
      setSidebar(BoardUserList)
    }

    onMounted(() => {
      openSiteList();
    })

    return {
      tab,
      currentSidebarTab,
      currentSidebar,
      setCurrentSidebarTab,
      OpenUserList,
      openSiteList,
      isSuperuser
    }
  }
}
</script>
<style lang="scss" scoped>

.sidebar-tab {
  cursor     : pointer;
  transition : color 0.2s;
}

.separate {
  display : inline-block;
  padding : rem-calc(0 8);
  opacity : 0.7;
}

.active {
  color : #008fd1;
}
</style>
