import api from '@/modules/api';
import {updateSiteList} from "@/modules/controls";
import {removeAccessFromSite} from "@/modules/controls";

const getAccess = async () => {
        const response = await api.access.get();
        return response.data
}

const createAccess = async (data, site) => {
        const access = {...data};
        const response = await api.access.post(access);
        await updateSiteList(site);
        return response.data

}

const editAccess = async (data) => {
        const response = await api.access.put(data);
        return response.data
}

const deleteAccess = async (data) => {
        const response = await api.access.delete(data);
        removeAccessFromSite(data.id);
        return response.data
}

export {
    getAccess,
    createAccess,
    editAccess,
    deleteAccess
}
