import Swal from 'sweetalert2';


export const showAlertDelete = (callback, data) => {
    Swal.fire({
        title: 'Точно удалить?',
        text: 'Ты не сможешь обратить это действие',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ага, давай уже',
        cancelButtonText: 'Нет, я еще подумаю',
    }).then(async (result) => {
        if (result.isConfirmed) {
            const confirm = await callback(data)
            if (confirm) {
                Swal.fire({
                    title: 'Удалено',
                    text: 'Удачного дня',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000,
                    timerProgressBar: true,
                })
            }
        }
    })
}


export const showToast = (string, icon = 'info') => {
    Swal.fire({
        icon: icon,
        title: string,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
}

export function showToastError(er) {
    const data = er.response.data.detail;
    let error = data;
    if (Array.isArray(data)) {
        let msg = [];
        data.forEach(detail => {
            msg.push(detail.msg);
        })

        error = msg.join('\n');
    }
    return showToast(error, 'error');
}

