<template>
  <div class="board-card">
    <div class="field">
      <label class="label">Пользователь</label>
      <div class="select is-fullwidth">
        <select v-model="access.user_id">
          <option v-for="user in users" :key="`a-${user.id}`" :value="user.id">
            {{ user.first_name }} {{ user.last_name }} {{ user.email }}
          </option>
        </select>
      </div>
    </div>
    <div class="field">
      <label class="label">Права</label>
      <div class="select is-fullwidth">
        <select v-model="access.access_level">
          <option value="staff">staff</option>
          <option value="superadmin">superadmin</option>
        </select>
      </div>
    </div>
    <div class="field">
      <button class="button is-link" @click="createAccess(access, site)">Создать</button>
    </div>
  </div>
</template>

<script>
import {users, loadUsers} from '@/modules/controls';
import {createAccess} from "@/modules/actions";
import {storage as site} from "@/storage/storageBoardData";
import {reactive, computed, onMounted} from 'vue';

export default {
  name: 'board-access-create',

  setup() {

    const id = computed( ()=> {
      return site.value.id
    });

    onMounted(async () => {
      await loadUsers();
    })

    const access = reactive({
      user_id: '',
      site_id: id,
      access_level: ''
    })

    return {
      site,
      users,
      access,
      createAccess,
    }
  }
}
</script>
