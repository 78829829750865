<template>
  <div class="board-card">
    <div class="field">
      <div class="field">
        <label class="label">Имя</label>
        <div class="control ">
          <input v-model="user['first_name']" class="input" type="text" placeholder="Фамилия">
        </div>
      </div>
      <label class="label">Фамилия</label>
      <div class="control ">
        <input v-model="user['last_name']" class="input" type="text" placeholder="Фамилия">
      </div>
    </div>
    <div class="field">
      <label class="label">E-mail</label>
      <div class="control ">
        <input v-model="user['email']" class="input" type="email" placeholder="E-mail">
      </div>
    </div>
    <div class="field">
      <label class="label">Пароль</label>
      <div class="control ">
        <input v-model="user['password']" class="input" type="password" placeholder="Пароль">
      </div>
    </div>
    <div class="field">
      <div class="switch">
        <label class="switch__wrapper">
          <input v-model="user['is_superuser']" type="checkbox" class="switch__input">
          <span class="switch__fake"></span>
          <span class="switch__label">Superuser</span>
        </label>
      </div>
    </div>
    <div class="field is-grouped">
      <div class="control">
        <button @click="editUser(user)" class="button is-link">Сохранить</button>
      </div>
    </div>
  </div>
</template>
<script>
import {storage} from '@/storage/storageBoardData';
import {editUser} from "@/modules/actions";

export default {
  name: 'board-user-edit',
  setup() {

    const user = {...storage.value,};

    return {
      editUser,
      user
    }
  }
}
</script>
