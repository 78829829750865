import api from "@/modules/api";
import {updateSiteList, updateAfterDeleteSite} from "@/modules/controls";

const getSites = async () => {
    try {
        const response = await api.sites.get();
        return response.data
    } catch (error) {
        await Promise.resolve(error)
    }
}

const createSite = async data => {
        const response = await api.sites.post(data);
        await updateSiteList(response.data);
        return response.data
}

const editSite = async site => {
        const response = await api.sites.put(site);
        await updateSiteList(response.data);
        return response.data

}

const deleteSite = async (id) => {
        const response = await api.sites.delete(id);
        await updateAfterDeleteSite(response.data);
        return response.data
}

const retrieveSite = async (id) => {
        const response = await api.sites.retrieve(id);
        return response.data
}

const loginExternalSite = async (id) => {
        return await api.external.get(id);
}

export {
    loginExternalSite,
    deleteSite,
    editSite,
    createSite,
    getSites,
    retrieveSite
}
