<template>
  <section class="section section-board">

    <div class="board">
      <div class="board__sidebar">
        <board-sidebar></board-sidebar>
      </div>

      <div class="container">
        <div class="card">
          <h2 class="card__title">Информация</h2>
          <component v-if="currentBoard !== null" :is="currentBoard"></component>
        </div>

      </div>
    </div>

  </section>
</template>
<script>
import BoardSidebar from "@/components/BoardSidebar";
import {storage as currentBoard} from '@/storage/storageBoardComponent';
import {setStorage as setUserData} from "@/storage/storageUserData";

export default {
  name: 'board',

  components: {
    BoardSidebar
  },

  setup() {
    setUserData();

    return {
      currentBoard,
    }
  }
}
</script>
<style lang="scss">
.section-board {
  min-height : 100vh;
  display    : grid;
}

.board {
  position              : relative;
  display               : grid;
  grid-gap              : rem-calc(30);
  grid-template-columns : 1fr;
  height                : 100%;

  @media all and (max-width : 1700px) {
    padding-left : rem-calc(330);
  }

  &__sidebar {
    position  : absolute;
    left      : 0;
    top       : 0;
    height    : 100%;
    max-width : rem-calc(310);
    width     : 100%;
  }
}

</style>
