<template>
  <div class="sidebar-list">
    <div class="sidebar-list__action">
      <button @click="openUserCreate();"
              class="button is-primary is-fullwidth">
        Добавить
      </button>
    </div>

    <ul class="sidebar-list__body" ref="usersWrapper">
      <li v-for="user in users" :key="`user-${user.id}`"
          @click="setCurrentTab(`user-${user.id}`); openUser(user)"
      >
        <div class="button-link" :class="{'is-active': currentTab === `user-${user.id}`}">
          <strong v-if="user['first_name'] || user['last_name']">
            {{ user['first_name'] }} {{ user['last_name'] }}
          </strong>
          {{ user.email }}
        </div>
      </li>
    </ul>
  </div>
</template>
<script>

import {setCurrentTab, currentTab} from "@/storage/storageBoardCurrentTab";
import scrollbar from "@/modules/scrollbar";
import {loadUsers, openUser, openUserCreate, users} from "@/modules/controls";
import {ref, onMounted} from 'vue';

export default {
  name: 'board-site-list',

  setup() {
    const usersWrapper = ref(null);

    onMounted(async () => {
      await loadUsers();
      scrollbar(usersWrapper.value);
    })

    return {
      users,
      currentTab,
      usersWrapper,
      setCurrentTab,
      openUser,
      openUserCreate
    }
  }
}
</script>

