import axios from "axios";
import {showToastError} from "@/modules/swal";

const token = window.localStorage.getItem('access_token');

const defaultErrorHandler = (error) => {
    return Promise.reject(error);
}

const $axios = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? '/api/' : 'http://localhost/api/',
});

$axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    switch (error.response.status) {
        case 401:
            window.location = '/login';
            break;
        case 400:
        case 422:
            showToastError(error);
            return defaultErrorHandler(error);
        default:
            return defaultErrorHandler(error);
    }
});

if (token) {
    $axios.defaults.headers.common['Authorization'] = token;
}


export default $axios;

