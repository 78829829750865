import {createRouter, createWebHistory} from 'vue-router';
import login from './components/TheLogin';
import logout from './components/TheLogout';
import board from './components/TheBoard';

const isLoggedIn = () => {
    return Boolean(localStorage.getItem('access_token'));
}

const routes = [
    {
        path: '/',
        redirect: '/board'
    },
    {
        path: '/board',
        component: board,
        meta: {requiresAuth: true}
    },
    {
        path: '/login',
        component: login,
        meta: {requiresAuth: false}
    },
    {
        path: '/logout',
        component: logout,
        meta: {requiresAuth: false}
    }
];
const router = createRouter({
    history: createWebHistory(),
    routes,

})

router.beforeEach((to) => {

    if (to.meta.requiresAuth && !isLoggedIn()) {

        return {
            path: '/login',

            query: {redirect: to.fullPath},
        }
    }
})

export default router
