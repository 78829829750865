import {ref, readonly} from 'vue';

const boardData = ref(null);
const storage = readonly(boardData);

const setStorage = (data) => {
    boardData.value = data;
}

export {
    setStorage,
    storage,
}
