<template>
  <div class="board-card">
    <button class="button is-primary is-outlined is-fullwidth" @click="openAccessCreate();">
      Прикрепить нового пользователя
    </button>
    <ul v-if="site.access_list.length > 0" class="board-card__list user-access">
      <li v-for="access in site.access_list" :key="`access-${access.id}`">
        <div class="user-control">
          <div class="user-control__title">
            <img class="user-control__icon" src="@/assets/access.svg" alt="user">
            <div class="user-control__name">
               <span v-if="access.user.first_name || access.user.last_name">
                  {{ access.user.first_name }} {{ access.user.last_name }}
               </span>
              <span v-else>
                  Ноунейм
                </span>
            </div>
            <div class="user-control__info">{{ access.user.email }}</div>
          </div>
          <ul class="user-control__actions">
            <li>
              <div class="switch is-small">
                <label class="switch__wrapper">
                  <input :checked="access.access_level === 'superadmin'" type="checkbox"
                         @click="editAccess(access)"
                         class="switch__input">
                  <span class="switch__fake"></span>
                  <span class="switch__label">Суперадмин</span>
                </label>
              </div>
            </li>
            <li>
              <button class="button is-danger is-small is-outlined" @click="showAlertDelete(deleteAccess, access)">
                Удалить
              </button>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import {showAlertDelete} from "@/modules/swal";
import {setStorage as setCurrentBoard} from '@/storage/storageBoardComponent';
import {storage as site} from '@/storage/storageBoardData';
import {openAccessCreate} from "@/modules/controls";
import {editAccess, deleteAccess} from "@/modules/actions";

export default {
  name: 'board-access-list',

  setup() {
    return {
      showAlertDelete,
      editAccess,
      deleteAccess,
      setCurrentBoard,
      // accessUser,
      openAccessCreate,
      site
    }
  }

}
</script>

<style lang="scss" scoped>
.user-access {
  padding-top : rem-calc(20);
}
</style>
