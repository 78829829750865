<template>
  <section class="section login-overflow">
    <div class="login-card">
      <div class="field">
        <label class="label">Логин</label>
        <div class="control ">
          <input v-model="dataForm.userName" class="input" type="text" placeholder="E-mail">
        </div>
      </div>
      <div class="field">
        <label class="label">Пароль</label>
        <div class="control ">
          <input v-model="dataForm.password" class="input" type="password" placeholder="Пароль">
        </div>
      </div>
      <div class="field is-grouped">
        <div class="control">
          <button @click="login(dataForm.userName, dataForm.password)" class="button is-primary">Отправить</button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import {reactive} from 'vue';
import auth from "@/modules/auth";

export default {
  name: 'login',

  setup() {
    const dataForm = reactive({
      userName: '',
      password: ''
    })

    const login = (user, pass) => {
      auth(user, pass);
    }
    return {
      login,
      dataForm
    }
  },


}
</script>
<style lang="scss">

.login-card {
  display          : block;
  padding          : rem-calc(40);
  background-color : #fff;
  border-radius    : $glob-radius;
  max-width        : rem-calc(600);
  width            : 100%;
  box-shadow       : rem-calc(0 8 24 0) rgb(0 0 0 / 10%);
}

.login-overflow {
  display         : flex;
  flex-direction  : column;
  justify-content : center;
  align-items     : center;
  min-height      : 100vh;
  width           : 100%;
}
</style>

