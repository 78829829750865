import {ref, readonly} from 'vue';

const site = ref(null);
const storage = readonly(site);

const setStorage = (data) => {
    site.value = data;
}

export  {
    setStorage as setCurrentTab,
    storage as currentTab
}
