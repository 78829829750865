<template>
  <div>пака</div>
</template>
<script>
import {logout} from "@/modules/auth";
import router from "@/router";

export default {
  name: 'logout',

  setup() {
    logout();

    router.push('/')
    return {
      logout
    }
  },
}
</script>

