import {ref, readonly} from 'vue';

const userData = ref('');
const storage = readonly(userData);

const setStorage = () => {
    const token = JSON.parse(localStorage.getItem('user'));
    if (token) {
        userData.value = token;
    }
}

const isSuperuser = () => {
    const superuser = storage.value.is_superuser;

    return superuser ? superuser : false

}

export {
    isSuperuser,
    setStorage,
    storage,
}
