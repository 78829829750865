import scrollbar from 'overlayscrollbars';
import 'overlayscrollbars/css/OverlayScrollbars.css';

export default function (element, options = {}) {
    const globalOptions = {
        textarea: {
            dynHeight: true
        },

        ...options
    }

    scrollbar(element, globalOptions);
}
