import {setCurrentTab} from "@/storage/storageBoardCurrentTab";
import {setStorage as setBoardComponent} from "@/storage/storageBoardComponent";
import {h} from "vue";

export const clearBoard = () => {
    setCurrentTab('');
    setBoardComponent(function () {
        return h('span', {}, [])
    });
}

