import api from "@/modules/api";
import {updateAfterDeleteUser, updateUserList} from "@/modules/controls";

export const getUsers = async () => {
        const response = await api.users.get();
        return response.data;
}

export const editUser = async (data) => {
        const response = await api.users.patch(data);
        await updateUserList(response.data);
        return response.data;
}

export const deleteUser = async (id) => {
        await api.users.delete(id);
        await updateAfterDeleteUser();
}

export const createUser = async (data) => {

        const response = await api.users.post(data);
        await updateUserList(response.data);
        return response.data;

}
