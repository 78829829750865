import {readonly, ref, markRaw} from 'vue';

const current = ref(null);
const storage = readonly(current);

const setStorage = (data) => {
    current.value = markRaw(data);
}

export {
    setStorage,
    storage
}
